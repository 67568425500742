<template>
  <div>
    <div>
      <d-body-top-bar :title="$t('views.playground.activities-area')">
        <div slot="tree">
          <label class="bg-doinsport-primary ml-1"> {{ $t('general.actions.list') }}</label>
        </div>
      </d-body-top-bar>
      <div class="container-fluid">
        <b-card>
          <b-row class="row-style d-flex align-items-center">
            <b-col>
              <d-body-nav-bar :tabs="$store.getters['menu/getTabs'].paygroundIndex"/>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <d-button
                text="views.playground.index.new-zone"
                class="d-btn btn d-btn-primary font-text-title"
                icon="fa fa-plus-circle mr-1"
                @on:button-click="newPlayground()"
              />
              <d-button
                text="views.playground.index.parameters.title"
                class="d-btn btn d-btn-default font-text-title ml-2"
                icon="fa fa-cog mr-1"
                @on:button-click="goToConfig()"
              />
              <div class="has-search ml-2">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  v-model="filter"
                  :placeholder="$t('components.doinsport.table.search')"
                  type="text"
                  class="form-control"
                  @keyup="manualFilter"
                >
              </div>
            </b-col>
          </b-row>

          <d-table
            :tablefields="tableFields"
            :items="items"
            :per-page="itemsPerPage"
            :totalRows="totalRows"
            :is-busy="isBusy"
            :tr-cursor-pointer="false"
            :per-page-enabled="true"
            @on:playground-photo-uploaded="onPlaygroundPhotoUploaded"
            @on:delete-playground-photo="onPlaygroundPhotoDelete"
            @on:playground:enabled-change="onEnabledChange"
            @on:data-table-btn:delete="onPlaygroundDetailsDelete"
            @on:data-table-btn:edit="onPlaygroundDetailsEdit"
            @filter:from-server="search"
            @current-page:update="onCurrentPageUpdate"
            @per-page:update="onPerPageUpdate"
          >
          </d-table>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {deleteClubPlayground, getPlaygrounds} from '@api/doinsport/services/playground/playground.api';
import {putClubPlayground} from "@api/doinsport/services/playground/playground.api";
import Playground from "@/classes/doinsport/Playground";
import {
  deleteClubPlaygroundPhoto,
  putClubPlaygroundPhoto
} from "@api/doinsport/services/playground/playground-photo.api";
import Photo from "@/classes/doinsport/Photo";
import {fromIdToIriReference, fromIriReferenceToId} from "@/utils/form";
import {SUCCESS} from "@plugins/flash";

export default {
  components: {
    NewPlayground: () => import('./new/Index')
  },
  data: () => ({
    display: false,
    filter: '',
    modalData: null,
    modalPlaygroundDisplay: false,
    playgroundsList: [],
    itemsPerPage: 10,
    currentPage: 1,
    totalRows: 1,
    isBusy: true,
    items: [],
  }),
  computed: {
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields() {
      return [
        {key: "photo", class: this.alignCellsToLeft, label: this.$t("views.playground.index.photo")},
        {key: "playgroundName", label: this.$t("views.playground.index.playground-name")},
        {key: "activity", label: this.$t("views.playground.index.activity"), sortable: true},
        {key: "area", label: this.$t("views.playground.index.area")},
        {key: "availability", label: this.$t("views.playground.index.availability")},
        {key: "timetables", label: this.$t("views.playground.index.calendar"), sortable: false},
        {key: "actions", label: this.$t("general.actions.actions")},
      ];
    },
    playgrounds() {
      return this.playgroundsList;
    }
  },
  mounted() {
    this.loadPlaygrounds(this.itemsPerPage, this.currentPage, '');
  },
  methods: {
    goToConfig() {
      this.$router.push({name: 'playgrounds_parameters'});
    },
    onPlaygroundPhotoDelete(row) {
      this.loadPlaygrounds(this.itemsPerPage, this.currentPage, '');
    },
    onPlaygroundPhotoUploaded() {
      this.loadPlaygrounds(this.itemsPerPage, this.currentPage, '');
    },

    onEnabledChange(row) {
      row.availability = !row.availability;

      const playground = this.playgroundsList.find(el => el.id === row.id);

      if ('undefined' !== typeof playground) {
        const serializedPlayground = new Playground(playground, {serialize: true})

        putClubPlayground(serializedPlayground.id, {enabled: row.availability})
          .catch(err => {
            this.loadPlaygrounds(this.itemsPerPage, this.currentPage, '');
          })
      }
    },

    newPlayground() {
      this.$store.dispatch('playgrounds/saveBlockPricesSelection', []);
      this.$store.dispatch('playgrounds/saveTimetablesSelection', []);
      this.$router.push('/playground/new');
    },

    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search(this.filter);
      }, 400);
    },

    search(filter) {
      this.loadPlaygrounds(this.itemsPerPage, this.currentPage, filter);
    },

    loadPlaygrounds(itemsPerPage, currentPage, search) {
      this.isBusy = true;

      getPlaygrounds(itemsPerPage, currentPage, search)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];
          this.currentPage = currentPage;
          this.itemsPerPage = itemsPerPage;
          this.playgroundsList = (response.data['hydra:member']);
          this.items = [];
          for (const playground of this.playgroundsList) {
            let item = {
              id: playground.id,
              photo: playground,
              playgroundName: playground.name,
              activity: "",
              availability: playground.enabled,
              type: "",
              timetables: playground.timetables,
              area: playground.surface ? playground.surface.name : this.$t('general.actions.undefined'),
              actions: ['edit', 'delete']
            }
            for (const i in playground.activities) {
              item.activity = parseInt(i) === 0 ? playground.activities[i].name : item.activity + ', ' + playground.activities[i].name;

              item.type = playground.activities[i].type;
            }
            this.items.push(item);
          }
        })
        .catch((error) => {
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },

    onCurrentPageUpdate(page) {
      this.loadPlaygrounds(this.itemsPerPage, page, this.filter);
    },

    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.loadPlaygrounds(itemsPerPage, this.currentPage, this.filter);
    },

    onPlaygroundDetailsDelete(playground) {
      deleteClubPlayground(playground.id)
        .then((response) => {
          this.$flash(null, this.$t('views.playground.actions.success-delete'), 3000, SUCCESS);
        })
        .catch((err) => {
          this.$flash(this.$t('general.actions.error'), this.$t('general.actions.error-notification'));
        })
        .finally(() => {
          this.loadPlaygrounds(this.itemsPerPage, this.currentPage, '');
        })
    },

    onPlaygroundDetailsEdit(playground) {
      this.$store.dispatch('playgrounds/saveTimetablesSelection', []);
      this.$store.dispatch('playgrounds/saveBlockPricesSelection', []);
      this.$router.push({name: 'playground_update', params: {id: playground.id}})
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.custom-margin-top {
  margin-top: 3px;
}

</style>
